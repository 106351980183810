<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <div>
    <v-row align="center">
      <v-col cols="12" xl="3" lg="4" md="6">
        <v-text-field
          solo
          solo-inverted
          flat
          placeholder="Suchen"
          prepend-inner-icon="mdi-magnify"
          dense
          hide-details
          v-model="search"
        />
      </v-col>
      <v-col cols="12" xl="2" lg="3" md="6">
        <v-btn
          depressed
          block
          height="38px"
          :to="{ name: 'UserCreate' }"
        >
          Neuer Nutzer
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="8" lg="10">
        <v-card>
          <v-tabs color="black" background-color="transparent">
            <v-tab> Aktiv Intern ({{ users_intern.length }}) </v-tab>
            <v-tab> Aktiv Extern ({{ users_extern.length }}) </v-tab>
            <v-tab>
              Archiviert Intern ({{ users_intern_archived.length }})
            </v-tab>
            <v-tab>
              Archiviert Extern ({{ users_extern_archived.length }})
            </v-tab>

            <v-tab-item>
              <v-card flat>
                <util-data-table
                  :items="users_intern"
                  :headers="headers"
                  :search="search"
                  editRoute="UserEdit"
                />
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <util-data-table
                  :items="users_extern"
                  :headers="headers"
                  :search="search"
                  editRoute="UserEdit"
                />
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <util-data-table
                  :items="users_intern_archived"
                  :headers="headers"
                  :search="search"
                  editRoute="UserEdit"
                />
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <util-data-table
                  :items="users_extern_archived"
                  :headers="headers"
                  :search="search"
                  editRoute="UserEdit"
                />
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    users_intern: [],
    users_extern: [],
    users_intern_archived: [],
    users_extern_archived: [],
    search: "",
    headers: [
      { text: "Pers. Nr.", value: "label" },
      { text: "Nachname", value: "last_name" },
      { text: "Vorname", value: "first_name" },
      { value: "action", sortable: false },
    ],
  }),
  computed: {},
  created() {
    this.indexUsers();
  },
  methods: {
    indexUsers() {
      this.axios
        .get("/users")
        .then((res) => {
          this.users_intern = res.data.users_intern;
          this.users_extern = res.data.users_extern;
          this.users_intern_archived = res.data.users_intern_archived;
          this.users_extern_archived = res.data.users_extern_archived;
        })
        .catch((err) => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    },
  },
};
</script>

<style scoped>
</style>