<template>
  <div style="width: 100%;" class="body-1">
     <v-row style="height: 100%" justify="center">
      <v-col cols="12" xl="9">
        <div class="mt-10" v-if="share">
          <div class="mb-2">
            <ui-chips-share />
          </div>
          <div>
            Geteilt am:
            {{ share.created_at | moment("DD.MM.YYYY HH:mm") }}
          </div>
          <div v-if="share.creator">
            Geteilt durch:
            {{ share.creator.name }}
          </div>

          <div class="mt-4" v-if="share.hint">Hinweis: {{ share.hint }}</div>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="inquiry">
      <v-col cols="12" xl="9">
     
    
         <inquiry-shares-section-timeline />  

    
      </v-col>

      <!--Delete dialog-->
     
    </v-row>
     <v-row justify="center">
      <v-col cols="12" xl="9">
        <div class="mt-10">
          <div class="text-overline">
            Kommentare
          </div>
          <div class="mt-4">
            <div v-for="(comment, index) in share.comments" :key="comment.id">
              <util-comments-parent-comment
                :commentProp="comment"
                :share="share"
                @pushComment="share.comments[index].children.push($event)"
              />
            </div>
          </div>
          <div class="mt-4">
            <v-form v-model="commentValid" ref="comment">
              <v-textarea
                v-model="body"
                :rules="[rules.required]"
                filled
                label="Neuer Kommentar"
                solo
              />
            </v-form>
            <div class="justify-end d-flex">
              <v-btn
                color="primary"
                @click="storeComment()"
                :disabled="!commentValid"
              >
                Absenden
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row> 
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({
    fab: false,
    shareDialog: false,
    deleteDialog: false,
    deleteLoading: false,
    commentValid: false,
    loading: false,
    isValid: false,
    share: {},
    body: ""
  }),
  computed: {
    inquiry() {
      return this.$store.getters["inquiry/currentInquiry"];
    },
     rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {},
  created() {
     if (this.$route.params.id) {
      this.showShare();
    }
    this.vuexShowCurrentInquiry(this.$route.params.id).then(() => {
      let breadCrumbs = [];
      if (
        this.inquiry &&
        this.inquiry.assignable_type === "App\\Models\\Team" &&
        this.$hasPermissions("consultant permission")
      ) {
        breadCrumbs = [
          {
            text: "Anfragen: Meinen Teams zugewiesen",
            to: {
              name: "Inquiries",
              query: { type: "assignedToTeams", view: "list" }
            },
            disabled: false,
            exact: true
          },
          {
            text: "Team: " + this.inquiry.assignable.name,
            to: {
              name: "TeamsShow",
              params: { id: this.inquiry.assignable_id }
            },
            exact: true,
            disabled: false
          },
          {
            text: "Anfrage: " + this.inquiry.name,
            to: {
              name: "InquiriesShow",
              params: { id: this.$route.params.id }
            },
            exact: true,
            disabled: false
          }
        ];
      } else {
        breadCrumbs = [
          {
            text: "Anfragen",
            to: { name: "Inquiries" },
            disabled: false,
            exact: true
          },
          {
            text: "Anfrage: " + this.inquiry.name,
            to: {
              name: "InquiriesShow",
              params: { id: this.$route.params.id }
            },
            exact: true,
            disabled: false
          }
        ];
      }

      this.$store.commit("application/setBreadCrumbs", breadCrumbs);
    });
  },
  methods: {
    ...mapActions({
      vuexShowCurrentInquiry: "inquiry/showInquiry"
    }),
    destroyInquiry() {
      this.deleteLoading = true;
      this.axios
        .delete("/inquiries/" + this.$route.params.id)
        .then(() => {
          this.$snotify.success("Die Anfrage wurde erfolgreich gelöscht.");
          this.$router.push({ name: "Inquiries" });
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error("Die Anfrage konnte nicht gelöscht werden.");
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    },
      showShare() {
      this.axios
        .get("/shares/" + this.$route.params.id)
        .then(r => {
          this.share = r.data.share;
          if (this.share.shareable_type === "App\\Models\\Inquiry") {
            this.$store.commit(
              "inquiry/setCurrentInquiry",
              this.share.shareable
            );
          } else if (this.share.shareable_type === "App\\Models\\InquiryItem") {
            this.$store.commit(
              "inquiryItem/setInquiryItem",
              this.share.shareable
            );
          }
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error("Beim Abruf ist ein Fehler aufgetreten.");
        });
    },
    storeComment() {
      let item = {};
      item.body = this.body;
      item.commentable_type = "App\\Models\\Share";
      item.commentable_id = this.share.id;
      this.axios
        .post("/comments", {
          comment: item
        })
        .then(r => {
          this.$snotify.success("Der Kommentar wurde erfolgreich gespeichert.");
          this.share.comments.push(r.data.comment);
          this.body = "";
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error("Der Kommentar konnte nicht gespeichert werden.");
        });
    }
  }
};
</script>

<style scoped></style>