<template>
  <v-row>
    <v-col cols="12" xl="6" lg="8">
      <v-form
        lazy-validation
        ref="form"
        v-model="isValid"
        @submit.prevent="submit()"
      >
        <div class="text-overline">
          Allgemein

          <util-info-tooltip>
            <template v-slot:text>
              Alle Ihnen zugewiesene Anfragen. <br />
              Entweder durch Sie, oder durch einen anderen Berater erstellt.
            </template>
          </util-info-tooltip>
        </div>

        <v-text-field
          v-model="customerType.name"
          filled
          label="Name"
          :rules="[rules.required, rules.short_text]"
          required
          :counter="280"
          solo
        />

        <v-textarea
          v-model="customerType.description"
          filled
          label="Beschreibung"
          :rules="[rules.long_text]"
          :counter="6000"
          solo
        />

        <div class="text-overline mt-4">
          Farbe
        </div>
        <v-color-picker v-model="customerType.color" hide-canvas hide-inputs />

        <div class="d-flex justify-end mt-6">
          <v-btn text :to="{ name: 'CustomerTypes' }" exact
            >Abbrechen</v-btn
          >
          <v-btn
            class="ml-2"
            color="primary"
            type="submit"
            :disabled="!isValid"
            :loading="loading"
          >
            Speichern
          </v-btn>
        </div>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    isValid: false,
    customerType: {
      name: "",
      description: "",
      color: ""
    }
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    $route(to) {
      if (to.name === "CustomerTypeEdit" && to.params.id) {
        this.showCustomerType();
      }
    }
  },
  created() {
    if (this.$route.params.id) {
      this.showCustomerType();
    }
  },
  methods: {
    submit() {
      const url = "/customer-types";
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.$route.params.id) {
          this.axios
            .patch(url + "/" + this.$route.params.id, {
              customerType: this.customerType
            })
            .then(() => {
              this.successMethod();
            })
            .catch(e => {
              this.errorMethod(e);
            });
        } else {
          this.axios
            .post(url, {
              customerType: this.customerType
            })
            .then(() => {
              this.successMethod();
            })
            .catch(e => {
              this.errorMethod(e);
            });
        }
      }
    },
    showCustomerType() {
      this.axios
        .get("/customer-types/" + this.$route.params.id)
        .then(r => {
          this.customerType = r.data.customerType;
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Abrufen des Kundentyps ist ein Fehler aufgetreten."
          );
        });
    },
    successMethod() {
      this.$router.push({
        name: "CustomerTypes"
      });
      this.$snotify.success("Der Kundentyp wurde erfolgreich gespeichert.");
    },
    errorMethod(e) {
      console.log(e);
      this.$snotify.error(
        "Beim Speichern des Kundentyps ist ein Fehler aufgetreten."
      );
    }
  }
};
</script>

<style scoped></style>
