<template>
  <div>
    <v-row align="center">
      <v-col cols="12" lg="4" md="6">
        <v-text-field
          solo
          solo-inverted
          flat
          placeholder="Suchen"
          prepend-inner-icon="mdi-magnify"
          dense
          hide-details
          v-model="search"
        />
      </v-col>
      <v-col cols="12" xl="2" lg="3">
        <inquiry-common-create-dialog
          :is-template="true"
          @close="createDialogKey++"
          :key="createDialogKey"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="8" lg="10">
        <v-card>
          <v-tabs color="black" background-color="transparent">
            <v-tab>Aktiv</v-tab>
            <v-tab>Archiviert</v-tab>

            <v-tab-item>
              <v-card flat>
                <util-data-table
                  :items="inquiries"
                  :headers="headers"
                  :search="search"
                  editRoute="SettingsInquiriesEditGeneral"
                />
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <util-data-table
                  :items="inquiries_archived"
                  :headers="headers"
                  :search="search"
                  editRoute="SettingsInquiriesEditGeneral"
                />
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    inquiries: [],
    inquiries_archived: [],
    search: "",
    headers: [
      { text: "Name", value: "name" },
      { text: "Beschreibung", value: "description" },
      { value: "action", sortable: false }
    ],

    // functional
    createDialogKey: 0
  }),
  computed: {},
  created() {
    this.indexInquiries();
  },
  methods: {
    indexInquiries() {
      this.axios
        .get("/inquiries?is_template=true")
        .then(res => {
          this.inquiries = res.data.inquiries;
          this.inquiries_archived = res.data.inquiries_archived;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    }
  }
};
</script>

<style scoped></style>
