<template>
  <div>
    <inquiry-wizard-wrapper />
    <v-row justify="center" class="mt-8">
      <v-col cols="12" xl="6" lg="8">
        <router-view />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({}),
  computed: {
    inquiry() {
      return this.$store.getters["inquiry/currentInquiry"];
    }
  },
  watch: {},
  created() {
    this.showCurrentInquiry(this.$route.params.id).then(() => {
      //
    });
    if (this.$route.name === "InquiriesEdit") {
      this.$router.push({
        name: "InquiriesEditGeneral",
        params: { id: this.$route.params.id }
      });
    }
  },
  methods: {
    ...mapActions({
      showCurrentInquiry: "inquiry/showInquiry",
      indexAvailableDocuments: "inquiry/indexAvailableDocuments"
    })
  }
};
</script>

<style scoped></style>