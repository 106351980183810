var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body-1",staticStyle:{"width":"100%"}},[(_vm.inquiry)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","xl":"9"}},[_c('inquiry-consultant-info'),_c('inquiry-consultant-timeline'),(_vm.$hasPermissions('consultant permission'))?_c('v-speed-dial',{attrs:{"right":"","bottom":"","direction":"top","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"fab":"","dark":"","color":"primary"},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(!_vm.fab)?_c('v-icon',[_vm._v("mdi-reorder-horizontal")]):_c('v-icon',[_vm._v("mdi-close")])],1)]},proxy:true}],null,false,868345708),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(!_vm.inquiry.policy_deleted_at)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"fab":"","small":"","exact":"","to":{
                  name: 'InquiriesSettings',
                  params: { id: _vm.$route.params.id }
                }}},[_c('v-icon',[_vm._v("mdi-cog-outline")])],1)],1)]}}],null,false,4123879507)},[_c('span',[_vm._v("Einstellungen")])]):_vm._e(),(!_vm.inquiry.policy_deleted_at)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":""},on:{"click":function($event){_vm.shareDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-share-variant")])],1)]}}],null,false,3178257747)},[_c('span',[_vm._v("Zugriffsberechtigungen verwalten")])]):_vm._e(),(_vm.inquiry.policy_deleted_at)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":""},on:{"click":function($event){_vm.deleteDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,false,1341735076)},[_c('span',[_vm._v("Anfrage entgültig löschen")])]):_vm._e()],1):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.shareDialog),callback:function ($$v) {_vm.shareDialog=$$v},expression:"shareDialog"}},[_c('v-card',[_c('inquiry-consultant-share',{attrs:{"type":"inquiry"}})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Wollen Sie die Anfrage wirklich löschen? ")]),_c('v-card-text',[_vm._v(" Wenn Sie bestätigen, wird die Anfrage unwiderruflich gelöscht. ")]),_c('v-card-actions',{staticClass:"px-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"error","loading":_vm.deleteLoading},on:{"click":function($event){_vm.destroyInquiry();
              _vm.deleteDialog = false;}}},[_vm._v(" Bestätigen ")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }