<template>
  <div style="height: 100%;">
    <v-row style="height: 100%" justify="center">
      <v-col cols="12" xl="9">
        <div class="mt-10" v-if="share">
          <div class="mb-2">
            <ui-chips-share />
          </div>
          <div>
            Geteilt am:
            {{ share.created_at | moment("DD.MM.YYYY HH:mm") }}
          </div>
          <div v-if="share.creator">
            Geteilt durch:
            {{ share.creator.name }}
          </div>

          <div class="mt-4" v-if="share.hint">Hinweis: {{ share.hint }}</div>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-10" v-if="share">
      <v-col
        cols="12"
        xl="9"
        v-if="share.shareable_type === 'App\\Models\\Inquiry'"
      >
        <!-- name and description -->
        <inquiry-consultant-header />
        <!-- current state, user-details -->
        <inquiry-consultant-info />
        <!-- current state, user-details -->
        <inquiry-consultant-timeline is-share />
      </v-col>
      <v-col
        cols="12"
        xl="6"
        lg="8"
        v-else-if="share.shareable_type === 'App\\Models\\InquiryItem'"
      >
        <!-- name and description -->
        <inquiry-items-info class="mb-8" is-share />
        <!-- upload requests -->
        <inquiry-items-upload-requests is-share />
        <!-- share item -->
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" xl="9">
        <div class="mt-10">
          <div class="text-overline">
            Kommentare
          </div>
          <div class="mt-4">
            <div v-for="(comment, index) in share.comments" :key="comment.id">
              <util-comments-parent-comment
                :commentProp="comment"
                :share="share"
                @pushComment="share.comments[index].children.push($event)"
              />
            </div>
          </div>
          <div class="mt-4">
            <v-form v-model="commentValid" ref="comment">
              <v-textarea
                v-model="body"
                :rules="[rules.required]"
                filled
                label="Neuer Kommentar"
                solo
              />
            </v-form>
            <div class="justify-end d-flex">
              <v-btn
                color="primary"
                @click="storeComment()"
                :disabled="!commentValid"
              >
                Absenden
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    commentValid: false,
    loading: false,
    isValid: false,
    share: {},
    body: ""
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    /*$route(to) {
      //
    }*/
  },
  created() {
    if (this.$route.params.id) {
      this.showShare();
    }
  },
  methods: {
    showShare() {
      this.axios
        .get("/shares/" + this.$route.params.id)
        .then(r => {
          this.share = r.data.share;
          if (this.share.shareable_type === "App\\Models\\Inquiry") {
            this.$store.commit(
              "inquiry/setCurrentInquiry",
              this.share.shareable
            );
          } else if (this.share.shareable_type === "App\\Models\\InquiryItem") {
            this.$store.commit(
              "inquiryItem/setInquiryItem",
              this.share.shareable
            );
          }
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error("Beim Abruf ist ein Fehler aufgetreten.");
        });
    },
    storeComment() {
      let item = {};
      item.body = this.body;
      item.commentable_type = "App\\Models\\Share";
      item.commentable_id = this.share.id;
      this.axios
        .post("/comments", {
          comment: item
        })
        .then(r => {
          this.$snotify.success("Der Kommentar wurde erfolgreich gespeichert.");
          this.share.comments.push(r.data.comment);
          this.body = "";
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error("Der Kommentar konnte nicht gespeichert werden.");
        });
    }
  }
};
</script>

<style scoped></style>
