<template>
  <div>
    <v-row align="center">
      <v-col cols="12" lg="4" md="6">
        <v-text-field
          solo
          solo-inverted
          flat
          placeholder="Suchen"
          prepend-inner-icon="mdi-magnify"
          dense
          hide-details
          v-model="search"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="10" lg="12">
        <v-card>
          <v-card flat>
            <util-data-table
              :items="inquiries"
              :headers="headers"
              :search="search"
              settingsRoute="InquiriesSettings"
            />
             <!-- settingsRoute="InquiriesSettings" -->
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    isValid: false,
    inquiries: [],
    search: "",
    headers: [
      { text: "Berater", value: "assignable.name" },
      { text: "Kunde", value: "customer_name" },
      { text: "Anliegen", value: "name" },
      { text: "Beschreibung", value: "description" },
      { text: "Erstellt", value: "created_at" },
      { value: "settings", align: "end" }
    ]
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {},
  created() {
    this.indexInstanceSettings();
  },
  methods: {
    indexInstanceSettings() {
      this.axios
        .get("/inquiries")
        .then(r => {
          this.inquiries = r.data.inquiries;
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Abruf der Anfragen ist ein Fehler aufgetreten."
          );
        });
    }
  }
};
</script>

<style scoped></style>
