<template>
  <div>
    <v-row>
      <v-col cols="12" xl="6" lg="8">
        <v-form ref="form" v-model="isValid" @submit.prevent="submit()">
          <div class="text-overline">
            Allgemein
          </div>
          <v-text-field
            v-model="document.name"
            filled
            label="Name"
            :rules="[rules.required, rules.short_text]"
            required
            :counter="280"
            solo
          />

          <v-text-field
            v-model="document.label"
            filled
            label="Kennung (bspw. Aktenzeichen)"
            :rules="[rules.short_text]"
            :counter="280"
            solo
          />

          <v-textarea
            v-model="document.description"
            filled
            label="Beschreibung"
            :rules="[rules.long_text, rules.required]"
            :counter="6000"
            solo
          />
          <v-text-field
            v-model="document.help"
            filled
            label="Bearbeitungshinweis für Berater"
            color="primary"
            :rules="[rules.short_text]"
            :counter="280"
            solo
          />
          <div class="text-overline mt-4">
            Hilfe für Kunden
          </div>
          <v-text-field
            v-model="document.name_customer"
            filled
            label="Name für Kunden"
            :rules="[rules.short_text]"
            :counter="280"
            solo
          />
          <v-textarea
            v-model="document.description_customer"
            filled
            label="Beschreibung für Kunden"
            :rules="[rules.long_text]"
            :counter="6000"
            solo
          />
          <v-text-field
            v-model="document.help_customer"
            filled
            label="Bearbeitungshinweis für Kunden"
            :rules="[rules.short_text]"
            :counter="280"
            solo
          />
          <div class="text-overline mt-4">
            Einstellungen
          </div>
          <!-- TODO: default value is instance setting -->
          <v-text-field
            v-model="document.max_filesize"
            filled
            type="number"
            min="0"
            step="1"
            label="Maximale Dateigröße"
            :rules="[rules.positiveIntegers]"
            suffix="MegaByte"
            solo
          />

          <v-select
            v-model="document.group_id"
            filled
            label="Ordner"
            :items="groups"
            item-text="name"
            item-value="id"
            clearable
            solo
          />

          <div class="text-overline mt-4">
            Datei bereitstellen
          </div>
          <div v-if="document.upload" class="my-4">
            <v-btn
              rounded
              depressed
              :loading="fileLoading"
              @click="
                downloadFile(
                  document.upload.id,
                  document.upload.filename_original
                )
              "
            >
              {{ document.upload.filename_original }} ({{
                document.upload.filesize
              }}
              KB)
              <v-icon right>
                mdi-download
              </v-icon>
            </v-btn>
          </div>
          <!-- TODO: show uploaded file          -->
          <util-file-drop :fileProp="file" @pass="file = $event" />

          <div class="d-flex justify-space-between mt-6">
            <div>
              <v-btn
                @click="destroyDocument()"
                depressed
                v-if="$route.params.id"
              >
                Archivieren
                <v-icon right>mdi-archive-arrow-down-outline</v-icon>
              </v-btn>
            </div>
            <div>
              <v-btn text :to="{ name: 'Documents' }" exact
                >Abbrechen</v-btn
              >
              <v-btn
                class="ml-2"
                color="primary"
                type="submit"
                :disabled="!isValid"
                :loading="loading"
              >
                <template v-slot:loader v-if="file">
                  <span>{{ uploadPercentage }} %</span>
                </template>
                Speichern
              </v-btn>
            </div>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    fileLoading: false,
    loading: false,
    isValid: false,
    document: {
      label: "",
      name: "",
      name_customer: "",
      description: "",
      description_customer: "",
      help: "",
      help_customer: "",
      max_file_size: "",
      group_id: ""
    },
    file: null,
    uploadPercentage: 0,

    groups: []
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    },
    hasHistory() {
      return this.$store.getters["application/hasHistory"];
    }
  },
  watch: {
    $route(to) {
      if (to.name === "DocumentEdit" && to.params.id) {
        this.showDocument();
        this.indexGroups();
      }
    }
  },
  created() {
    this.indexGroups();
    if (this.$route.params.id) {
      this.showDocument();
    }
  },
  methods: {
    indexGroups() {
      this.axios
        .get("/groups?type=documents")
        .then(res => {
          this.groups = res.data.groups;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error(
            "Beim Abruf der Ordner ist ein Fehler aufgetreten."
          );
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.$route.params.id) {
          this.axios
            .patch("/documents/" + this.$route.params.id, {
              document: this.document
            })
            .then(() => {
              if (this.file) {
                this.submitFile(this.$route.params.id);
              } else {
                this.$snotify.success(
                  "Das Dokument wurde erfolgreich gespeichert."
                );
                this.$router.push({
                  name: "Documents"
                });
              }
            })
            .catch(e => {
              console.log(e);
              this.$snotify.error(
                "Beim Speichern des Dokuments ist ein Fehler aufgetreten."
              );
            });
        } else {
          this.axios
            .post("/documents", {
              document: this.document
            })
            .then(r => {
              if (this.file) {
                this.submitFile(r.data.id);
              } else {
                this.$router.push({
                  name: "Documents"
                });
                this.$snotify.success(
                  "Das Dokument wurde erfolgreich gespeichert."
                );
              }
            })
            .catch(e => {
              console.log(e);
              this.$snotify.error(
                "Beim Speichern des Dokuments ist ein Fehler aufgetreten."
              );
            });
        }
      }
    },
    submitFile(id) {
      let formData = new FormData();

      formData.append("file", this.file);

      this.axios
        .post("upload/document/" + id, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = Math.round(
              (100 * progressEvent.loaded) / progressEvent.total
            );
          }.bind(this)
        })
        .then(() => {
          this.$router.push({
            name: "Documents"
          });
          this.$snotify.success("Das Dokument wurde erfolgreich gespeichert.");
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error("Beim Hochladen ist ein Fehler aufgetreten.");
        });
    },
    showDocument() {
      this.axios
        .get("/documents/" + this.$route.params.id)
        .then(r => {
          this.document = { ...r.data.document };
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Abrufen des Dokuments ist ein Fehler aufgetreten."
          );
        });
    },

    downloadFile(id, name) {
      this.fileLoading = true;
      this.axios
        .get("/upload/" + id + "/download", {
          responseType: "blob"
        })
        .then(r => {
          const url = URL.createObjectURL(new Blob([r.data]));
          const fileName = name;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.fileLoading = false;
        });
    },
    destroyDocument() {
      this.axios
        .delete("/documents/" + this.$route.params.id)
        .then(() => {
          this.$router.push({ name: "Documents" });
          this.$snotify.success("Das Dokument wurde erfolgreich archiviert.");
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim archivieren des Dokuments ist ein Fehler aufgetreten."
          );
        });
    }
  }
};
</script>

<style scoped></style>
