<template>
  <div style="width: 100%;" class="body-1">
    <v-row justify="center">
      <v-col cols="12" xl="6" lg="8" md="10" sm="12">
        <util-inquiry-settings-edit class="mb-8" />
        <util-inquiry-settings-additional />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({}),
  computed: {
    inquiry() {
      return this.$store.getters["inquiry/currentInquiry"];
    }
  },
  watch: {},
  created() {
    this.vuexShowCurrentInquiry(this.$route.params.id).then(() => {
      let breadCrumbs = [];
      if (!this.$route.query.settings) {
        breadCrumbs = [
          {
            text: "Anfragen",
            to: { name: "Inquiries" },
            disabled: false,
            exact: true
          },
          {
            text: "Anfrage: " + this.inquiry.name,
            to: {
              name: "InquiriesShow",
              params: { id: this.$route.params.id }
            },
            exact: true,
            disabled: false
          },
          {
            text: "Einstellungen",
            to: {
              name: "InquiriesSettings",
              params: { id: this.$route.params.id }
            },
            exact: true,
            disabled: false
          }
        ];
      } else {
        breadCrumbs = [
          {
            text: "Statistiken: Anfragen",
            to: {
              name: "InquiriesStatistics"
            },
            exact: true,
            disabled: false
          },
          {
            text: "Einstellungen: " + this.inquiry.name,
            to: {
              name: "InquiriesSettings",
              params: { id: this.$route.params.id }
            },
            exact: true,
            disabled: false
          }
        ];
      }
      this.$store.commit("application/setBreadCrumbs", breadCrumbs);
    });
  },
  methods: {
    ...mapActions({
      vuexShowCurrentInquiry: "inquiry/showInquiry"
    })
  }
};
</script>

<style scoped></style>
