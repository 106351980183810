<template>
  <v-row>
    <v-col cols="12" xl="6" lg="8" md="10">
      <v-form
        lazy-validation
        ref="form"
        v-model="isValid"
        @submit.prevent="submit()"
      >
        <div class="text-overline">
          Allgemein

          <util-info-tooltip>
            <template v-slot:text>
              Hier können Sie das Formular benennen, eine ausführlichere
              Beschreibung hinzufügen und auswählen, zu welchem Produkt das
              Formular gehören soll.
            </template>
          </util-info-tooltip>
        </div>

        <v-text-field
          v-model="form.name"
         
          label="Name"
          :rules="[rules.required, rules.short_text]"
          required
          :counter="280"
          solo
        />

        <v-textarea
          v-model="form.description"
          filled
          label="Beschreibung"
          :rules="[rules.long_text]"
          :counter="6000"
           solo
        />

        <v-select
          v-model="form.product_id"
          filled
          label="Produkt"
          :rules="[rules.required]"
          required
          clearable
          :items="products"
          item-value="id"
          item-text="name"
           solo
        />

        <div class="text-overline mt-4">
          Formularfelder

          <util-info-tooltip>
            <template v-slot:text>
              Fügen Sie zuerst, mit einem Klick auf FELD HINZUFÜGEN, dem
              Formular ein neues Formularfeld hinzu. Hinzugefügte Felder können
              sowohl mit der Maus verschoben, als auch mit dem Button ENTFERNEN,
              wieder aus dem Formular gelöscht werden.
            </template>
          </util-info-tooltip>
        </div>
        <util-form-items :formItemsProp="form.form_items" />
        <div class="d-flex justify-end mt-6">
          <v-btn text :to="{ name: 'ProductForms' }" exact>Abbrechen</v-btn>
          <v-btn
            class="ml-2"
            color="primary"
            type="submit"
            :disabled="!isValid"
            :loading="loading"
          >
            Speichern
          </v-btn>
        </div>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    isValid: false,
    form: {
      name: "",
      description: "",
      product_id: null,
      form_items: []
    },
    products: []
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    $route(to) {
      if (to.name === "ProductFormEdit" && to.params.id) {
        this.showForm();
        this.indexProducts();
      }
    }
  },
  created() {
    if (this.$route.params.id) {
      this.showForm();
    }
    this.indexProducts();
  },
  methods: {
    submit() {
      const url = "/forms";
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.$route.params.id) {
          this.axios
            .patch(url + "/" + this.$route.params.id, {
              form: this.form
            })
            .then(() => {
              this.successMethod();
            })
            .catch(e => {
              this.errorMethod(e);
            });
        } else {
          this.axios
            .post(url, {
              form: this.form
            })
            .then(() => {
              this.successMethod();
            })
            .catch(e => {
              this.errorMethod(e);
            });
        }
      }
    },
    showForm() {
      this.axios
        .get("/forms/" + this.$route.params.id)
        .then(r => {
          this.form = { ...r.data.form };
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Abrufen des Formulars ist ein Fehler aufgetreten."
          );
        });
    },

    indexProducts() {
      this.axios
        .get("/products")
        .then(res => {
          this.products = res.data.products;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    },

    successMethod() {
      this.$router.push({
        name: "ProductForms"
      });
      this.$snotify.success("Das Formular wurde erfolgreich gespeichert.");
    },
    errorMethod(e) {
      console.log(e);
      this.$snotify.error(
        "Beim Speichern des Formulars ist ein Fehler aufgetreten."
      );
    }
  }
};
</script>

<style scoped></style>