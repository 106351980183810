<template>
  <div style="width: 100%;" class="body-1">
    <v-row justify="center" v-if="inquiryItem">
      <v-col cols="12" xl="6" lg="8">
        <!-- name and description -->
        <inquiry-items-info class="mb-8" />
        <!-- upload requests -->
        <inquiry-items-upload-requests />
        <!-- share item -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({
    fab: false,
    shareDialog: false,
    deleteDialog: false,
    deleteLoading: false
  }),
  computed: {
    inquiryItem() {
      return this.$store.getters["inquiryItem/inquiryItem"];
    }
  },
  watch: {},
  created() {
    this.showInquiryItem({ id: this.$route.params.id }).then(() => {
      let breadCrumbs = [];

      if (
        this.inquiryItem.inquiry &&
        this.inquiryItem.inquiry.assignable_type === "App\\Models\\Team" &&
        this.$hasPermissions("consultant permission")
      ) {
        breadCrumbs = [
          {
            text: "Anfragen: Meinen Teams zugewiesen",
            to: {
              name: "Inquiries",
              query: { type: "assignedToTeams", view: "list" }
            },
            disabled: false,
            exact: true
          },
          {
            text: "Team: " + this.inquiryItem.inquiry.assignable.name,
            to: {
              name: "TeamsShow",
              params: { id: this.inquiryItem.inquiry.assignable_id }
            },
            exact: true,
            disabled: false
          },
          {
            text: "Anfrage: " + this.inquiryItem.inquiry.name,
            to: {
              name: "InquiriesShow",
              params: { id: this.inquiryItem.inquiry_id }
            },
            exact: true,
            disabled: false
          },
          {
            text: "Anforderung: " + this.inquiryItem.name,
            to: {
              name: "InquiryItemsShow",
              params: { id: this.$route.params.id }
            },
            exact: true,
            disabled: false
          }
        ];
      } else {
        breadCrumbs = [
          { text: "Anfragen", to: { name: "Inquiries" }, disabled: false },
          {
            text: "Anfrage: " + this.inquiryItem.inquiry.name,
            to: {
              name: "InquiriesShow",
              params: { id: this.inquiryItem.inquiry_id }
            },
            exact: true,
            disabled: false
          },
          {
            text: "Anforderung: " + this.inquiryItem.name,
            to: {
              name: "InquiryItemsShow",
              params: { id: this.$route.params.id }
            },
            exact: true,
            disabled: false
          }
        ];
      }
      this.$store.commit("application/setBreadCrumbs", breadCrumbs);
    });
  },
  methods: {
    ...mapActions({
      showInquiryItem: "inquiryItem/show"
    })
  }
};
</script>

<style scoped></style>
